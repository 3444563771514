import axios from "@axios";
import travelers from "@mocks/mockTravelers";
import reservations from "@mocks/mockReservations";

export default {
  namespaced: true,
  state: {
    currentGroup: null,
    currentGroupId: null,
    currentGroupName: null,
    transportations: null,
    packages: null,
    accommodations: null,
    itineraries: null,
    documents: null,
    accounting: null,
    additionalOptions: null
  },
  getters: {
    getCurrentGroupId(state) {
      return state.currentGroupId;
    },
    getCurrentGroupObject(state) {
      return {
        name: state.currentGroupName,
        link: `/groups/${state.currentGroupId}`,
      };
    },
    getCurrentGroupBillings(state) {
      return {
        name: "Fiches comptables",
        link: `/groups/${state.currentGroupId}#billings`,
      };
    },
  },
  mutations: {
    clearGroup(state) {
      state.currentGroup = null;
      state.currentGroupId = null;
      state.currentGroupName = null;
      state.transportations = null;
      state.accommodations = null;
      state.packages = null;
      state.itineraries = null;
      state.documents = null;
      state.accounting = null;
      state.additionalOptions = null
    },
    setCurrentGroupId(state, id) {
      state.currentGroupId = id;
    },
    setCurrentGroupName(state, name) {
      state.currentGroupName = name;
    },
    setCurrentGroup(state, group) {
      state.currentGroup = group;
    },
    setTransportations(state, transportations) {
      state.transportations = transportations;
    },
    setAccommodations(state, accommodations) {
      state.accommodations = accommodations;
    },
    setPackages(state, packages) {
      state.packages = packages;
    },
    setItineraries(state, itineraries) {
      state.itineraries = itineraries;
    },
    setDocuments(state, documents) {
      state.documents = documents;
    },
    setAccounting(state, accounting) {
      state.accounting = accounting;
    },
    setAccountingAssociationStatus(state, payload) {
      let association = state.accounting?.associations.find((x) => {
        return (
          x.occupation_type_id === payload.association.occupation_type_id &&
          x.package_id === payload.association.package_id &&
          x.traveler_type === payload.association.traveler_type
        );
      });

      if (association) {
        association.status = payload.status;
      }
    },
    setAdditionalOptions(state, additionalOptions) {
      state.additionalOptions = additionalOptions;
    },
  },
  actions: {
    fetchGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/groups`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchGroup(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/invoice/invoices/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createBasicGroup(ctx, currentGroup) {
      let body = {
        name: currentGroup.name,
        guide: currentGroup.guide,
        departure_date: currentGroup.departureDate,
        return_date: currentGroup.returnDate,
        type: currentGroup.type,
        difficulty: currentGroup.groupDifficulty,
        booking_limit: currentGroup.bookingLimit,
        payment_limit: currentGroup.paymentLimit,
        inclusions: [],
        exclusions: [],
      };
      return new Promise((resolve, reject) => {
        axios
          .post(`/groups`, body)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response);
            } else {
              reject(error);
            }
          });
      });
    },
    addTransportsToCurrentGroup(ctx, transportOptions) {
      return new Promise((resolve, reject) => {
        axios
          //.post(`/groups`, body)
          .get(`/users/me`)
          .then((response) => {
            ctx.commit("setCurrentGroupTransportOptions", transportOptions);
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response);
            } else {
              reject(error);
            }
          });
      });
    },
    addAccommodationsToCurrentGroup(ctx, accommodationOptions) {
      return new Promise((resolve, reject) => {
        axios
          //.post(`/groups`, body)
          .get(`/users/me`)
          .then((response) => {
            ctx.commit(
              "setCurrentGroupAccommodationOptions",
              accommodationOptions
            );
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response);
            } else {
              reject(error);
            }
          });
      });
    },
    addPackagesToCurrentGroup(ctx, pkgs) {
      return new Promise((resolve, reject) => {
        axios
          //.post(`/groups`, body)
          .get(`/users/me`)
          .then((response) => {
            ctx.commit("setCurrentGroupPackages", pkgs);
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response);
            } else {
              reject(error);
            }
          });
      });
    },
    addPackagesRulesToCurrentGroup(ctx, pkgsRules) {
      return new Promise((resolve, reject) => {
        axios
          //.post(`/groups`, body)
          .get(`/users/me`)
          .then((response) => {
            ctx.commit("setCurrentGroupPackagesRules", pkgsRules);
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response);
            } else {
              reject(error);
            }
          });
      });
    },
    fetchReservations(ctx) {
      return new Promise((resolve, reject) => {
        resolve({ data: reservations });
      });
    },
    fetchTravelers(ctx) {
      return new Promise((resolve, reject) => {
        resolve({ data: travelers });
      });
    },
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
};
