import Vue from 'vue';

import router from './router';
import store from './store';
import App from './App.vue';

import * as Sentry from "@sentry/vue";

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import setupAllPlugins from "@/plugins";
import i18n from '@/plugins/i18n'
import 'typeface-roboto/index.css';
import VueHtmlToPaper from 'vue-html-to-paper';

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
Vue.component(FeatherIcon.name, FeatherIcon)

import VueMask from 'v-mask'
Vue.use(VueMask);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css'); // For form-wizard

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faUserSecret, faCreditCard } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    '@core/scss/core.scss',
    '@/assets/scss/style.scss',
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);

/* add icons to the library */
library.add(faUserSecret)
library.add(faCreditCard)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;
setupAllPlugins();

Sentry.init({
  Vue,
  dsn: "https://3d0912c0d05c49db9b2819aba248bb27@o4504724736638976.ingest.sentry.io/4504724737622016",
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT ?? 'local',
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false
    }),
    new Sentry.Replay()
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
